<template>
    <div class="row">
        <div class="col-md-7">
            <proveedor-datos-datos :proveedor="proveedor" :id="id" />
        </div>
        <div class="col-md-5" v-if="(id > 0)">
            <proveedor-datos-acceso :proveedor="proveedor" :id="id"/>
            <proveedor-datos-opciones :proveedor="proveedor" :id="id" />
        </div>
    </div>
</template>
<script>
import ProveedorDatosDatos from './ProveedorDatosDatos.vue'
import ProveedorDatosAcceso from './ProveedorDatosAcceso.vue'
import ProveedorDatosOpciones from './ProveedorDatosOpciones.vue'
export default {
    props: ['proveedor','id'],
    components: {
        'proveedor-datos-datos': ProveedorDatosDatos,
        'proveedor-datos-acceso': ProveedorDatosAcceso,
        'proveedor-datos-opciones': ProveedorDatosOpciones,
    },
    data() {
        return {}
    }
}
</script>
