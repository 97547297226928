<template>
<seccion-datos :titulo="proveedoracceso" tipo="danger" @cambios="guardar()">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label >{{ $t('general.usuario') }}</label>
                        <input type="text" class="form-control nombre_completo dato" id="nombre_completo" v-model="usuario.nombre">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label >{{ $t('general.clave') }}</label>
                        <input type="password" class="form-control nombre_completo dato" v-model="usuario.contraseña" id="nombre_completo">
                    </div>   
                </div>
            </div>
            <!-- Se omite el acceso de email/clave ya que no se usa ni están contemplado en el acceso al webmail -->
</seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['proveedor', 'id'],
    data() {
        return {
            usuario: [],
            proveedoracceso: "Acceso",
           
        }
    },
    methods: {
        async cargarDatosProveedor() {
            /* get  modulos/pwgsapi/index.php/usuarios/:id */
            const api = new PwgsApi;
            this.usuario = await api.get('usuarios/' + this.proveedor.idusuario);
            this.proveedoracceso = this.$t("general.acceso");
            
        },
         guardar() {
            /* put   modulos/pwgsapi/index.php/proveedores/:id */
           // const api = new PwgsApi;
          //  const subidadatos = {};
            //await api.put('proveedores/' + this.proveedor.id_proveedor, subidadatos);
            this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });

        },
    },
    mounted() {
        this.cargarDatosProveedor();
    },
    watch: {
        proveedor() {
            this.cargarDatosProveedor();
        }
    },
}
</script>